export const VARIABLE_GUARDAR = 'VARIABLE_GUARDAR';
export const VARIABLE_GUARDAR_EXITO = 'VARIABLE_GUARDAR_EXITO';
export const VARIABLE_GUARDAR_ERROR = 'VARIABLE_GUARDAR_ERROR';
export const VARIABLE_CARGAR_PROCESO = 'VARIABLE_CARGAR_PROCESO';
export const VARIABLE_CARGAR_EXITO = 'VARIABLE_CARGAR_EXITO';
export const VARIABLE_CARGAR_ERROR = 'VARIABLE_CARGAR_ERROR';
export const ELIMINAR_VARIABLES_PROCESO = 'ELIMINAR_VARIABLES_PROCESO';
export const ELIMINAR_VARIABLES_EXITO = 'ELIMINAR_VARIABLES_EXITO';
export const ELIMINAR_VARIABLES_ERROR = 'ELIMINAR_VARIABLES_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADING_SUCCESS = 'USER_LOADING_SUCCESS';
export const USER_LOADING_ERROR = 'USER_LOADING_ERROR';
export const CONVOCATORIA_CARGAR_PROCESO = 'CONVOCATORIA_CARGAR_PROCESO';
export const CONVOCATORIA_CARGAR_EXITO = 'CONVOCATORIA_CARGAR_EXITO';
export const CONVOCATORIA_CARGAR_ERROR = 'CONVOCATORIA_CARGAR_ERROR';
export const CONVOCATORIA_INICIADA_CARGAR_PROCESO = 'CONVOCATORIA_INICIADA_CARGAR_PROCESO';
export const CONVOCATORIA_INICIADA_CARGAR_EXITO = 'CONVOCATORIA_INICIADA_CARGAR_EXITO';
export const CONVOCATORIA_INICIADA_CARGAR_ERROR = 'CONVOCATORIA_INICIADA_CARGAR_ERROR';

export const CHECK_BASES_LOADING = 'CHECK_BASES_LOADING';
export const CHECK_BASES_LOADING_EXITO = 'CHECK_BASES_LOADING_EXITO';
export const CHECK_BASES_LOADING_ERROR = 'CHECK_BASES_LOADING_ERROR';

export const DOWNLOAD_BASES_LOADING = 'DOWNLOAD_BASES_LOADING';
export const DOWNLOAD_BASES_EXITO = 'DOWNLOAD_BASES_EXITO';
export const DOWNLOAD_BASES_ERROR = 'DOWNLOAD_BASES_ERROR';


export const DATOS_BOLSA_TRABAJO_LOADING = "DATOS_BOLSA_TRABAJO_LOADING";
export const DATOS_BOLSA_TRABAJO_EXITO = "DATOS_BOLSA_TRABAJO_EXITO";
export const DATOS_BOLSA_TRABAJO_ERROR = "DATOS_BOLSA_TRABAJO_ERROR";

export const PUESTOS_LOADING = "PUESTOS_LOADING";
export const PUESTOS_LOADING_EXITO = "PUESTOS_LOADING_EXITO";
export const PUESTOS_LOADING_ERROR = "PUESTOS_LOADING_ERROR";