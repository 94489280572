import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from 'redux/reducers';

const store = createStore(
    reducer,
    compose(applyMiddleware(thunk),
        typeof window === 'object' && typeof  window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
    ),
);

export default store;