import { container, customColor } from "assets/jss/material-kit-react.js";

const footerStyle = {

  footLeft: {
    width: '980px',
    margin: 'auto',
    padding: '30px 13px 50px',
    backgroundColor: "gray",
    borderTop: '2px solid #FFFFFF'
  },

  footRight: {
    float: 'right',
    width: '450px',
  },

  custom: {
    margin: '0',
    padding: '0',
  },

  sepBull: {
    float: "left",
    padding: "0 0 6px 5px",
    fontSize: "1.4em",
    color: "#CCCCCC",
  },

  listaFooter: {
    float: "left",
  },

  aFooter: {
    padding: "0 0 0 10px",
    fontSize: "1.3em",
    color: "#ba0c2f",
    fontWeight: "normal",
  },

  clearFooter: {
    clear: "both",
  },

  imgFooter: {
    width: "100 %",
  },

  footDirA: {

    color: '#ba0c2f', fontWeight: 'bold'
  },

  footDirB: {
    float: 'left',
    width: '295px',
    fontSize: '1.5em',
    color: '#333333',
    padding: '0 0 0 5px',
  },


  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: '30px 13px 50px',
    width: '980px', margin: 'auto',
    backgroundColor: '#F2F2F2',
    borderTop: '2px solid #FFFFFF'
  },
  a: {
    color: customColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
};
export default footerStyle;
