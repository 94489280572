import { combineReducers } from 'redux';
import BolsaTrabajoReducer from './BolsaTrabajoReducer';
import checkBasesReducer from './checkBasesReducer';
import downloadBasesReducer from './downloadBasesReducer';
import PuestosReducer from './PuestosReducer';
import userReducer from './userReducer';
import variablesReducer from './variablesReducer';

export default combineReducers({
    user: userReducer,
    variables: variablesReducer,
    checkBases: checkBasesReducer,
    downloadBases: downloadBasesReducer,
    bolsaTrabajo: BolsaTrabajoReducer,
    puestos: PuestosReducer,
});