export function Validation(variable, value) {
	console.log(variable);

	let $rsp = false;
	switch (variable) {
		case "var4":
			if (!validadorNIFNIE(value) && value != "") {
				$rsp = "NIF no válido";
			}
			break;
		case "var9":
			if (!validadorCP(value) && value != "") {
				$rsp = "Código Postal no válido";
			}
			break;
		case "var6":
			if (!validadorPhone(value) && value != "") {
				$rsp = "Teléfono no válido";
			}
			break;
		case "var7":
			if (!validadorPhone(value) && value != "") {
				$rsp = "Móvil no válido";
			}
			break;
		case "var11":
			if (!validadorEmail(value) && value != "") {
				$rsp = "Email no válido";
			}

			break;
		default:
			break;
	}
	return $rsp;
}

function validadorCP(value) {
	// if (!/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(value)) {
	if (!/([0-9]){5}/.test(value)) {
		return false;
	}
	return true;
}

function validadorPhone(value) {
	if (!/([0-9]){9}/.test(value)) {
		return false;
	}
	return true;
}

function validadorEmail(value) {
	if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
		return false;
	}
	return true;
}

function validadorCIF(value) {
	if (!value || value.length !== 9) {
		return false;
	}

	var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
	var digits = value.substr(1, value.length - 2);
	var letter = value.substr(0, 1);
	var control = value.substr(value.length - 1);
	var sum = 0;
	var i;
	var digit;

	if (!letter.match(/[A-Z]/)) {
		return false;
	}

	for (i = 0; i < digits.length; ++i) {
		digit = parseInt(digits[i]);
		if (isNaN(digit)) {
			return false;
		}

		if (i % 2 === 0) {
			digit *= 2;
			if (digit > 9) {
				digit = parseInt(digit / 10) + (digit % 10);
			}

			sum += digit;
		} else {
			sum += digit;
		}
	}

	sum %= 10;
	if (sum !== 0) {
		digit = 10 - sum;
	} else {
		digit = sum;
	}

	if (letter.match(/[ABEH]/)) {
		return String(digit) === control;
	}
	if (letter.match(/[NPQRSW]/)) {
		return letters[digit] === control;
	}

	return String(digit) === control || letters[digit] === control;
}

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
	if (!/^([0-9a-zA-Z]\s?){24}$/.test(input)) {
		return false;
	}
	var CODE_LENGTHS = {
		AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
		CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
		FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
		HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
		LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
		MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
		RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
		AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
		SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
	};
	var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
		code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
		digits;
	// check syntax and length
	if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
		return false;
	}
	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
		return letter.charCodeAt(0) - 55;
	});
	// final check
	if (mod97(digits) == 1) {
		return true;
	}
	return false;
}

function mod97(string) {
	var checksum = string.slice(0, 2), fragment;
	for (var offset = 2; offset < string.length; offset += 7) {
		fragment = String(checksum) + string.substring(offset, offset + 7);
		checksum = parseInt(fragment, 10) % 97;
	}
	return checksum;
}

function validadorNIFNIE(value) {
	var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
	var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
	var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
	var str = value.toString().toUpperCase();

	if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

	var nie = str
		.replace(/^[X]/, '0')
		.replace(/^[Y]/, '1')
		.replace(/^[Z]/, '2');

	var letter = str.substr(-1);
	var charIndex = parseInt(nie.substr(0, 8)) % 23;

	if (validChars.charAt(charIndex) === letter) return true;

	return false;
}
