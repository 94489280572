import {
    USER_LOADING,
    USER_LOADING_SUCCESS,
    USER_LOADING_ERROR
} from "../types";

import { isAdminAuth, isLogin } from "utils/API_V2";

// User info
export function getUserInfo() {
    return (dispatch) => {
        dispatch(getUserInfoProceso());
        try {
            return isLogin().then(res => {
                if (res.data != null) {
                    if (res.data.status == true) {
                        dispatch(getUserInfoExito(res.data.role));
                    } else {
                        dispatch(getUserInfoError());
                    }
                } else {
                    dispatch(getUserInfoError());
                }
            })
        } catch (error) {
            dispatch(getUserInfoError());
        }
    }
}

const getUserInfoProceso = () => ({
    type: USER_LOADING
})
const getUserInfoExito = (role: string) => ({
    type: USER_LOADING_SUCCESS,
    role: role
})
const getUserInfoError = () => ({
    type: USER_LOADING_ERROR
})