import { PUESTOS_LOADING, PUESTOS_LOADING_ERROR, PUESTOS_LOADING_EXITO } from "redux/types"




const initialState = {
    status: false,
    loading: false,
    puestos: [],

}

export default function (state = initialState, action: { type: any; }) {
    switch (action.type) {
        case PUESTOS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case PUESTOS_LOADING_ERROR:
            return {
                ...state,
                loading: false,

            }
        case PUESTOS_LOADING_EXITO:
            return {
                ...state,
                status: true,
                puestos: action.payload,


            }

        default:
            return state;
            break;
    }
}