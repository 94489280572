import { DATOS_BOLSA_TRABAJO_ERROR, DATOS_BOLSA_TRABAJO_EXITO, DATOS_BOLSA_TRABAJO_LOADING } from "redux/types"




const initialState = {
    status: false,
    loading: false,
    datos: {},

}

export default function (state = initialState, action: { type: any; }) {
    switch (action.type) {
        case DATOS_BOLSA_TRABAJO_LOADING:
            return {
                ...state,
                loading: true,
            }
        case DATOS_BOLSA_TRABAJO_ERROR:
            return {
                ...state,
                loading: false,

            }
        case DATOS_BOLSA_TRABAJO_EXITO:
            return {
                ...state,
                status: true,
                datos: action.payload,


            }

        default:
            return state;
            break;
    }
}
