import React, {useState, useEffect} from "react";
import * as API from "utils/API_V2";
import { toast } from 'react-toastify';
import { Validation } from "utils/Validation";
import PARAMS from "utils/PARAMS";
var time = [];

// Recibe array map
export function validateFields(fields){
  var BreakException = {};
  var error = [];
  error['status'] = true;
  var field = '';

  try {
    fields.forEach(function(el, i){
      if ((el === '' || el === null || el === []) && error) {
        error['status'] = false;
        field = i;
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  if (!error.status) {
    error['message'] = 'El campo '+field+' no puede estar vacio';
  }

  return error;
}


// Función keyup con timeout del filtro
export function guardarVariable(event, variable, value, orden, tipo_id, convocatoria_id, usuario_id = null ) {
  if (variable && variable != "undefined") {
    if (!orden) {
      orden = 0;
    }
    clearInterval(time[variable + '' + orden]);
    time[variable + '' + orden] = setTimeout(function () {
      let validacion = Validation(variable, value);
      if (validacion) {
        if (event) {
          event.target.parentElement.parentElement.classList.add('errorAicor');
          event.target.parentElement.parentElement.classList.remove('successAicor');
          toast(validacion, {type: "warning"});
        }
      } else {
        if (event) {
          event.target.parentElement.parentElement.classList.remove('errorAicor');
          event.target.parentElement.parentElement.classList.add('successAicor');
        }
        API.guardarVariable(variable, value, orden, usuario_id, tipo_id, convocatoria_id);
        return false;
      }
    }, 1000);
  }
}

//Parse Float Fixeo cuando no es numero y que no genere error.
export function parseFloatNullToZero(value) {
  if (value == null || isNaN(value) || value === "") {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export function formatear_variables_multiples(datos) {
  var variables_multiples = [];
  for (let data in datos) {
    if (Array.isArray(datos[data])) {
      datos[data].forEach(element => {
        let key = element.variable;
        let valor = element.valor;
        let orden = element.orden - 1;
        if (!variables_multiples[orden]) {
          variables_multiples[orden] = [];
        }
        variables_multiples[orden][key] = valor;
      });
    }
  }
  return variables_multiples;
}


export async function descargarPDF(tipo){
  toast('Descarga en proceso', {type: "info"});
  let res = await API.obtenerNombrePDF(tipo);

  if (res.data.error) {
    toast(res.data.error, {type: 'error',autoClose: false});
  }else{
    let nombre = res.data;
    let res2 = await API.getPDF(nombre, tipo);
    // console.log(res2)
    if (res2.error) {
      toast('Se ha producido un error en la descarga', {type: "default"});
    }else{
      let mensaje = '- Se ha generado un formulario , que ha sido descargado a su dispositivo. El formulario ha de presentarse en la sede electrónica del Ayuntamiento de Córdoba (https://sede.cordoba.es/cordoba)';
      // if (tipo == 'alta_terceros') {
      //   mensaje = <div>{'-	Se ha descargado un modelo de certificado de entidad bancaria. Dicho modelo, una vez relleno, firmado y sellado por la entidad bancaria , ha de adjuntarse a la solicitud en el siguiente paso. '}<br/>{'-	Importnte: el modelo de certificado bancario ha de ser obligatorio SIEMPRE, MARQUEs o no marques la casilla siguiente. '}<br/>{'-	Cuenta bancaria IBAN: hay que chequearlo. Hay que comprobar que es válido, y siempre ha de ser obligatorio'}</div>
      // }
      toast(mensaje, {
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        hideProgressBar: true,
        style: {fontWeight: 'bold', textAlign: 'left', color: PARAMS.firstColor, border: `1px solid ${PARAMS.firstColor}`, borderRadius: 5, width: 400}
      });
      //Descargar
      const url = window.URL.createObjectURL(new Blob([res2.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nombre);
      document.body.appendChild(link);
      link.click();
      toast('Descarga realizada correctamente', {type: "success"});
    }
  }
}






export async function descargarPDFAutoliquidacion(convocatoria){

    let res2 = await API.getPDFAutoliquidacion(convocatoria);
    if (res2.error) {
      toast('Se ha producido un error en la descarga', {type: "default"});
    }else{
      //Descargar
      const url = window.URL.createObjectURL(new Blob([res2.data], { type: 'application/pdf;base64' }));
      //Visualizar
      window.open(url, '_blank')
      //Descarga
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'Modelo_Autoliquidacion.pdf');
      // document.body.appendChild(link);
      // link.click();



      toast('Descarga realizada correctamente', {type: "success"});
    }
}

export async function descargarPDFConvocatoria(convocatoria_id, tipo_id){
  toast('Descarga en proceso', {type: "info"});
  let res = await API.obtenerNombrePDF(convocatoria_id, tipo_id);

  if (res.data.error) {
    toast(res.data.error, {type: 'error',autoClose: false});
  }else{
    let nombre = res.data;
    let res2 = await API.descargarPDFConvocatoria(convocatoria_id, tipo_id);
    if (res2.error) {
      toast('Se ha producido un error en la descarga', {type: "default"});
    }else{
      // let mensaje = "No debe alterar el nombre del documento descargado y es obligatorio registrar la solicitud a través de la sede electrónica del Excmo. Ayuntamiento de Córdoba";
      // toast(mensaje, {
      //   position: "top-center",
      //   autoClose: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   hideProgressBar: true,
      //   style: {fontWeight: 'bold', textAlign: 'left', color: PARAMS.firstColor, border: `1px solid ${PARAMS.firstColor}`, borderRadius: 5, width: 400}
      // });
      //Descargar
      const url = window.URL.createObjectURL(new Blob([res2.data], { type: 'application/pdf;base64' }));
      //Visualizar
      // window.open(url, '_blank')
      //Descarga
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nombre);
      document.body.appendChild(link);
      link.click();
      toast('Descarga realizada correctamente', {type: "success"});
    }
  }
}
