import React from "react";
import { render } from 'react-dom'

export function validateFields(fields) {
  var BreakException = {};
  var error = [];
  error['status'] = true;
  var field = '';

  try {
    fields.forEach(function (el, i) {
      if ((el === '' || el === null) && error) {
        error['status'] = false;
        field = i;
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  if (!error.status) {
    error['message'] = 'El campo ' + field + ' no puede estar vacio';
  }

  return error;
}

export function diff_fechas_dias(date1, date2) {
  var dt1 = new Date(date1);
  var dt2 = new Date(date2);
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
}
export function find_in_simple_array(array, search) {

  if (Array.isArray(array)) {
    return array.find(function (element) {
      return element == search;
    });
  } else {
    return false;
  }
}
