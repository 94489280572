// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from "react-toastify";

// Instancia para api
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000,
});

instance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            // toast("Usuario no autorizado", {type: "error"});
          }
          break;
        case 404:
          // toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    } else {
      //toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Instancia para api responseType blob
var instance_blob = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_blob.defaults.responseType = "blob";
instance_blob.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_blob.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      if (localStorage.getItem("apiToken")) {
        toast("Sesión caducada", { type: "error" });
        instance_blob.get("logout");
        localStorage.removeItem("apiToken");
        localStorage.removeItem("userType");
        window.location.replace(PARAMS.baseUrl);
      } else {
        // toast("Usuario no autorizado", {type: "error"});
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Instancia para api formdata
var instance_archivo = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_archivo.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_archivo.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      if (localStorage.getItem("apiToken")) {
        toast("Sesión caducada", { type: "error" });
        instance_archivo.get("logout");
        localStorage.removeItem("apiToken");
        localStorage.removeItem("userType");
        window.location.replace(PARAMS.baseUrl);
      } else {
        // toast("Usuario no autorizado", {type: "error"});
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Instancia para ws formData
var instance_fd = axios.create({
  baseURL: PARAMS.apiUrl,
});

instance_fd.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_fd.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance_fd.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          // toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    }
  }
);

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    } else {
      resolved.error = "Se ha producido un error";
    }
  }

  return resolved;
}

// Solicitudes Bolsa

// export async function solicitudesBolsas(findBy, page, perPageData, tipoFormulario) {
//   return await resolve(
//     instance.post('solicitudes/bolsas', { findBy, page, perPageData, tipoFormulario }).then(res => res.data)
//   )
// }

// Solicitudes Plazas
// export async function solicitudesPlazas(findBy, page, perPageData, tipoFormulario) {
//   return await resolve(
//     instance.post('solicitudes/plazas', { findBy, page, perPageData, tipoFormulario }).then(res => res.data)
//   )
// }
// Actualización Solicitud Bolsa
// export async function solicitudBolsaEmpleoUpdate(solicitud) {
//   return await resolve(
//     instance.post(`solicitudBolsaEmpleo/update/${solicitud.id}`, solicitud).then(res => res.data)
//   )
// }

// Actualización Solicitud Plaza
// export async function solicitudPlazaUpdate(solicitud) {
//   return await resolve(
//     instance.post(`solicitudPlaza/update/${solicitud.id}`, solicitud).then(res => res.data)
//   )
// }

// checkEstadoRespuesta
export async function checkEstadoRespuesta(convocatoria_id) {
  return await resolve(
    instance
      .post(`checkEstadoRespuesta`, { convocatoria_id })
      .then((res) => res.data)
  );
}

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(instance.post("isAdminAuth").then((res) => res.data));
}

// Ruta para verificar tipo de usuario (no token)
export async function isLogin() {
  return await resolve(instance.post("isLogin").then((res) => res.data));
}

export async function actualizarCheck(tipo) {
  return await resolve(
    instance.post("actualizarCheckBases", { tipo }).then((res) => res.data)
  );
}

export async function comprobarCheck(tipo) {
  return await resolve(
    instance.post("comprobarCheckBases", { tipo }).then((res) => res.data)
  );
}

export async function login(email, password, userType) {
  return await resolve(
    instance
      .post("login", { email, password, userType })
      .then((res) => res.data)
  );
}

export async function register(nombre, email, password, userType) {
  return await resolve(
    instance
      .post("register", { nombre, email, password, userType })
      .then((res) => res.data)
  );
}

export async function resetPass(email) {
  return await resolve(
    instance.post("resetPassword/create", { email }).then((res) => res.data)
  );
}

export async function logout() {
  return await resolve(instance.get("logout").then((res) => res.data));
}

export async function getUser() {
  return await resolve(instance.post("usuarios/show").then((res) => res.data));
}

export async function cargarDatosBolsaTrabajo(tipo) {
  return await resolve(
    instance.post("datosBolsaTrabajo", { tipo }).then((res) => res.data)
  );
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("usuarios/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function userStore(
  nombre,
  email,
  password,
  apellidos,
  puesto,
  company,
  activo
) {
  return await resolve(
    instance
      .post("usuarios/store", {
        nombre,
        email,
        password,
        apellidos,
        puesto,
        company,
        activo,
      })
      .then((res) => res.data)
  );
}

export async function userUpdate(
  id,
  nombre,
  email,
  password,
  apellidos,
  puesto,
  company,
  activo
) {
  return await resolve(
    instance
      .post(`usuarios/update/${id}`, {
        nombre,
        email,
        password,
        apellidos,
        puesto,
        company,
        activo,
      })
      .then((res) => res.data)
  );
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then((res) => res.data)
  );
}

export async function logIndex() {
  return await resolve(instance.post("log/index").then((res) => res.data));
}

export async function variableShow(variable) {
  return await resolve(
    instance.post(`pregunta/show`, { variable }).then((res) => res.data)
  );
}

export async function getRespuestas(findBy, page, perPageData, tipo_id, id_convocatoriaABuscar, idSolicitud, convocatoriasCerradas) {
  return await resolve(
    instance.post(`respuesta/index`, { findBy, page, perPageData, tipo_id, id_convocatoriaABuscar, idSolicitud, convocatoriasCerradas }).then(res => res.data)
  )
}

export async function respuestasExcel() {
  return await resolve(
    instance.post(`respuesta/excel`).then((res) => res.data)
  );
}

export async function existeSubsanacion(id) {
  return await resolve(
    instance
      .post(`respuesta/existe_subsanacion`, { id: id })
      .then((res) => res.data)
  );
}

export async function cargarPuestos() {
  return await resolve(
    instance.post(`cargar/convocatorias`).then((res) => res.data)
  );
}

export async function cargarVariables(variables, user_id, tipo, convocatoria) {
  return await resolve(
    instance
      .post(`respuesta/cargar`, { variables, user_id, tipo, convocatoria })
      .then((res) => res.data)
  );
}

export async function guardarVariable(
  variable,
  value,
  orden,
  user_id,
  tipo,
  convocatoria,
  sumatorioTotal
) {
  return await resolve(
    instance
      .post("respuesta/guardar", {
        variable,
        value,
        orden,
        user_id,
        tipo,
        convocatoria,
        sumatorioTotal,
      })
      .then((res) => res.data)
  );
}

export async function actualizarSumatorio(user_id, solicitud_id, tipo, convocatoria, auxBaremacion) {
  return await resolve(
    instance
      .post("respuesta/actualizar-sumatorio", {
        user_id,
        solicitud_id,
        tipo,
        convocatoria,
        auxBaremacion,
      })
      .then((res) => res.data)
  );
}

export async function eliminarVariables(
  variable,
  orden,
  user_id,
  tipo,
  convocatoria
) {
  return await resolve(
    instance
      .post("respuesta/eliminar", {
        variable,
        orden,
        user_id,
        tipo,
        convocatoria,
      })
      .then((res) => res.data)
  );
}

export async function guardarArchivo(
  file,
  variable,
  convocatoria,
  tipoFormulario
) {
  let data = new FormData();
  data.append("file", file);
  data.append("variable", variable);
  data.append("convocatoria", convocatoria);
  data.append("tipoFormulario", tipoFormulario);
  let settings = { headers: { "content-type": "multipart/form-data" } };

  return await resolve(
    instance_archivo
      .post("guardarArchivo", data, settings)
      .then((res) => res.data)
  );
}

export async function obtenerArchivo(
  variable,
  user_id,
  convocatoria,
  tipoFormulario
) {
  let data = new FormData();
  data.append("variable", variable);
  data.append("user_id", user_id);
  data.append("convocatoria", convocatoria);
  data.append("tipoFormulario", tipoFormulario);
  return await resolve(
    instance_archivo.post("obtenerArchivo", data).then((res) => res.data)
  );
}

export async function borrarArchivo(
  variable,
  file_name,
  convocatoria,
  tipoFormulario
) {
  let data = new FormData();

  data.append("nombre_archivo", file_name);
  data.append("variable", variable);
  data.append("convocatoria", convocatoria);
  data.append("tipoFormulario", tipoFormulario);
  return await resolve(
    instance_archivo.post("borrarArchivo", data).then((res) => res.data)
  );
}

export async function importCodigos(input) {
  let data = new FormData();
  data.append("file", input);
  let settings = { headers: { "content-type": "multipart/form-data" } };

  return await resolve(
    instance_archivo.post("codigosUsuario/import", data).then((res) => res.data)
  );
}

export async function obtenerNombrePDF(convocatoria, tipo) {
  return await resolve(
    instance
      .post("pdf/obtenernombre", { convocatoria, tipo })
      .then((res) => res.data)
  );
}

export async function getPDF(nombre, tipo) {
  let data = new FormData();
  data.append("nombrearchivo", nombre);

  return await resolve(
    instance_blob.post(`pdf/${tipo}`, data).then((res) => res.data)
  );
}
export async function getPDFAutoliquidacion(convocatoria = false) {
  return await resolve(
    instance_blob
      .post(`pdf/get_autoliquidacion`, { convocatoria })
      .then((res) => res.data)
  );
}
export async function descargarPDFConvocatoria(convocatoria_id, tipo_id) {
  return await resolve(
    instance_blob
      .post(`pdf/convocatoria`, { convocatoria_id, tipo_id })
      .then((res) => res.data)
  );
}

export async function getConvocatorias(tipo_id, fecha) {
  let data = new FormData();
  data.append("fecha", fecha);
  data.append("tipo_id", tipo_id);
  return await resolve(
    instance
      .post("respuesta/listado_convocatorias_disponibles", data)
      .then((res) => res.data)
  );
}
export async function getConvocatoriasIniciadas(fecha, tipoFormulario) {
  // let data = new FormData();
  let data = {
    fecha: new FormData(),
    tipoFormulario: tipoFormulario,
  };
  return await resolve(
    instance
      .post("respuesta/listado_convocatorias_iniciadas", data)
      .then((res) => res.data)
  );
}

// Funciones Jose
export async function descargarArchivo(url) {
  let nombre = url.split("/");
  nombre = nombre[nombre.length - 1];
  return await resolve(
    instance.post(`convocatorias/descargarArchivo`, { url }).then((res) => {
      var a = document.createElement("a");
      a.href = res.data;
      a.download = nombre;
      a.click();
    })
  );
}

export async function convocatoriasIndex(findBy, page, perPageData) {
  return await resolve(
    instance
      .post("convocatorias/index", { findBy, page, perPageData })
      .then((res) => res.data)
  );
}

// export async function solicitudesIndex(findBy, page, perPageData) {
//   return await resolve(
//     instance.post('solicitudes/index', { findBy, page, perPageData }).then(res => res.data)
//   )
// }

export async function convocatoriaStore(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance_fd.post("convocatorias/store", fd).then((res) => res.data)
  );
}

export async function convocatoriaUpdate(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance_fd
      .post(`convocatorias/update/${convocatoria.id}`, fd)
      .then((res) => res.data)
  );
}

export async function convocatoriaDestroy(id) {
  return await resolve(
    instance.post(`convocatorias/destroy/${id}`).then((res) => res.data)
  );
}

export async function listConvocatorias() {
  return await resolve(
    instance.post(`convocatorias/list`).then((res) => res.data)
  );
}

export async function getListadoConvocatoriasTipo(id){
    return await resolve(
      instance.post(`convocatorias/obtenerListadoConvocatoriasPorTipo`,{id}).then(res => res.data)
    )
}

// Obtener nombre convocatoria
export async function obtenerNombreConvocatoria(id) {
  return await resolve(
    instance
      .post(`convocatorias/obtenerNombreConvocatoria/${id}`)
      .then((res) => res.data)
  );
}
