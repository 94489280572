import { DOWNLOAD_BASES_ERROR, DOWNLOAD_BASES_EXITO, DOWNLOAD_BASES_LOADING } from "redux/types"


const initialState = {
    status: false,
    loading: true,

}

export default function (state = initialState, action: { type: any; }) {
    switch (action.type) {
        case DOWNLOAD_BASES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case DOWNLOAD_BASES_EXITO:
            return {
                ...state,
                loading: false,



            }
        case DOWNLOAD_BASES_ERROR:
            return {
                ...state,
                status: true,
                loading: false,


            }

        default:
            return state;
            break;
    }
}