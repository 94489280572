import { CHECK_BASES_LOADING, CHECK_BASES_LOADING_ERROR, CHECK_BASES_LOADING_EXITO } from "redux/types"



const initialState = {
    status: false,
    loading: true,

}

export default function (state = initialState, action: { type: any; }) {
    switch (action.type) {
        case CHECK_BASES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CHECK_BASES_LOADING_ERROR:
            return {
                ...state,
                loading: false,



            }
        case CHECK_BASES_LOADING_EXITO:
            return {
                ...state,
                status: true,
                loading: true,


            }

        default:
            return state;
            break;
    }
}
