import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// import Login from "components/Login";
// import Register from "components/Register";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import image from "assets/img/bg3.jpg";

// const useStyles = makeStyles(styles);

 export default function AvisoLegal() {
  // const [login, setLogin] = React.useState(true);
  // const [apiToken, setApiToken] = React.useState(null);
  //
  // useEffect(() => {
  //   setApiToken(localStorage.getItem("apiToken"));
  // }, []);

  // const classes = useStyles();
  // const { ...rest } = props;
  return (
    <GridContainer style={{maxWidth: 1200, margin: '0 auto'}}>
        <GridItem>
            <h1 style={{textTransform: 'uppercase', margin: '25px 0', textAlign: 'center'}}>Términos y condiciones</h1>
            <div>
                <h2 style={{margin: '25px 0'}}>CLAUSULA INFORMATIVA PARA CORREOS ELECTRÓNICOS</h2>
                <p>Este mensaje y sus adjuntos contienen información confidencial y reservada, dirigida exclusivamente a su destinatario. Si ha recibido este mensaje por error, se ruega lo notifique inmediatamente por esta misma vía y borre el mensaje de su sistema. Le informamos que el correo electrónico vía Internet no permite asegurar ni la confidencialidad de los mensajes que se transmiten ni la correcta recepción de los mismos. De conformidad con el artículo 13 de la sección 2 del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, le informamos que el responsable del tratamiento es CEMENTERIOS Y SERVICIOS FUNERARIOS MUNICIPALES DE CORDOBA, S.A, que dicho tratamiento se lleva a cabo para gestionar las comunicaciones de la empresa. La base legal que permite legitimar este tratamiento es el consentimiento prestado por usted, el cual entendemos otorgado al mantener esta correspondencia. Se comunicarán datos a terceros para poder llevar a cabo las finalidades descritas en esta información. Puede usted acceder, rectificar y suprimir sus datos, así como otros derechos, dirigiéndose por escrito al email cecosam@ayuncordoba.es. Puede usted obtener información ampliada sobre protección de datos solicitándola al email cecosam@ayuncordoba.es.</p>
                <p>This message and its attachments contain confidential and reserved information that is exclusively addressed to its recipient. If you have received this message in error, you are required to notify it immediately by the same means and delete the message from your system. You are hereby informed that electronic mail sent through the Internet does not guarantee confidentiality of the messages being sent or their correct receipt. According to article 13 of Section 2 of (EU) Regulation 2016/679 of the European Parliament and the Council, of 27th of April 2016, relating to the protection of individuals’ personal data and the free circulation of data, we hereby inform you that the entity responsible for its handling is CEMENTERIOS Y SERVICIOS FUNERARIOS MUNICIPALES DE CORDOBA, S.A, which shall handle such data for the purposes of managing the company’s communications. The legal precept that enables the legitimate handling of your data is your consent, which is hereby understood as provided by way of this message. Your data will be passed on to third parties for the purposes above described. You are entitled to access, rectify and delete your data, as well as to exercise any other rights in writing on email address cecosam@ayuncordoba.es. Should you require further information on data protection, please send an email on cecosam@ayuncordoba.es. </p>
            </div>
            <div>
                <h2 style={{margin: '25px 0'}}>CLAUSULA INFORMATIVA PARA CURRICULUMS VITAE</h2>
                <h3 style={{margin: '15px 0'}}>INFORMACIÓN BASICA SOBRE PROTECCIÓN DE DATOS DE ACUERDO AL REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS</h3>
                <p>De conformidad con el artículo 13 de la sección 2 del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, le informamos que el responsable del tratamiento es CEMENTERIOS Y SERVICIOS FUNERARIOS MUNICIPALES DE CORDOBA, S.A, que dicho tratamiento se lleva a cabo para los procesos de selección de personal y para la gestión contable, fiscal y administrativa. La base legal que permite legitimar este tratamiento es el consentimiento prestado por usted para el tratamiento de los datos contenidos en su CV. Se comunicarán datos a terceros para poder llevar a cabo las finalidades descritas en este documento. Puede usted acceder, rectificar y suprimir sus datos, así como otros derechos, dirigiéndose por escrito a cecosam@ayuncordoba.es. Puede usted obtener información ampliada sobre protección de datos solicitándola a cecosam@ayuncordoba.es</p>
            </div>
        </GridItem>
    </GridContainer>
  );
}
